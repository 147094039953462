<template>
    <!-- <div id="app"> -->
    <!-- <v-app id="inspire"> -->
    <v-card dark flat>
        <v-main :class="cssMain">
            <v-container>
                <v-layout wrap>
                    <v-flex xs12 grid-list-md fluid fill-height :class="cssMain" dark>
                        <v-layout wrap align-center justify-center class="LoginAlign">
                            <v-flex lg8 md8 sm8 xs12 v-show="isLogin" :class="cssLogin">
                                <v-card class="elevation-12">
                                    <v-toolbar dark color="red">
                                        <v-toolbar-title>Contratos Cosmic</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-text-field prepend-icon="fa-regular fa-user" name="login"
                                                v-model="tLogin.user" :rules="nameRules" required label="No Empleado"
                                                type="text" class="bounce-in-top"
                                                color="blue-grey lighten-2"></v-text-field>
                                            <v-text-field prepend-icon="fa fa-lock" name="password" required
                                                v-model="tLogin.pass" :rules="passRules" color="blue-grey lighten-2"
                                                label="Contraseña" id="password" type="password"></v-text-field>
                                        </v-form>
                                    </v-card-text>

                                    <v-card-actions>
                                        <p>Recuperar contraseña</p>
                                        <v-spacer></v-spacer>
                                        <v-btn large :disabled="!valid" @click="submit()" color="dark red">
                                            Entrar
                                        </v-btn>
                                    </v-card-actions>
                                    <v-btn @click="precovery" color="dark red" class="white--text" absolute fab small left
                                        bottom>
                                        <v-icon>fa fa-lock</v-icon>
                                    </v-btn>
                                </v-card>
                                <v-alert v-model="alert" dismissible :type="success_alert" transition="scale-transition">
                                    {{ alert_msg }}
                                </v-alert>
                            </v-flex>

                            <v-flex lg10 md10 sm12 xs12 v-show="isRecovery" :class="cssRecovery">
                                <v-card class="elevation-12">
                                    <v-toolbar dark color="dark red">
                                        <v-toolbar-title>Recupera tu contraseña</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-form ref="form2" v-model="valid" lazy-validation>
                                            <v-text-field prepend-icon="fa-regular fa-user" name="login"
                                                v-model="tLogin.user" :rules="nameRules" required label="No Empleado"
                                                type="text" color="blue-grey lighten-2"></v-text-field>
                                            <v-text-field v-model="tRecover.Imss" label="No de I.M.S.S." :rules="imssRules"
                                                required></v-text-field>
                                            <v-layout row wrap>
                                                <v-flex xs12>
                                                    <v-switch v-model="cambiaMail" label="Ingresar correo de recuperación?"
                                                        color="warning" value="red" hide-details></v-switch>
                                                </v-flex>
                                                <v-flex xs12>
                                                    <v-text-field v-model="tRecover.Email" :disabled="!cambiaMail"
                                                        label="Correo " :rules="emailRules" required></v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        </v-form>

                                    </v-card-text>
                                    <v-card-actions>
                                        <p>Login</p>
                                        <v-spacer></v-spacer>
                                        <v-btn large :disabled="!valid2" @click="recovery(true)" color="dark red">
                                            Recuperar
                                        </v-btn>
                                    </v-card-actions>
                                    <v-btn @click="plogin" color="red" class="white--text" absolute fab small left bottom>
                                        <v-icon>fa fa-lock</v-icon>
                                    </v-btn>

                                </v-card>
                                <v-alert v-model="alert" dismissible :type="success_alert" transition="scale-transition">
                                    {{ alert_msg }}
                                </v-alert>
                            </v-flex>

                            <v-flex lg4 md4 sm4 xs12 align-center justify-center>
                                <img class="bounce logo" src="../assets/logo_csm2.png">
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-card>
    <!-- </v-app> -->
    <!-- </div> -->
</template>


<script>
import axios from 'axios'
import { debuglog } from 'util';

export default {
    props: {
        appname: String,
        dev: String,
    },
    data() {
        return {
            isLogin: true,
            isRecovery: false,
            cambiaMail: false,
            valid: false,
            valid2: true,
            cssRecovery: '',
            alert_color: 'primary',
            warning_color: 'warning',
            success_alert: 'success',
            alert_msg: 'Acceso concedido',
            cssMain: 'main_contenedor',
            cssLogin: 'puff-in-center',
            alert: false,
            isVisible: false,
            tUser: { empleado: '', nombre: '', materno: '', paterno: '', Email: '' },
            project: 'FC621CEC-9201-4902-9970-76F279D432E2',
            tLogin: {
                user: '',
                pass: '',
                dev: false,

                isVisible: false,
                IdUserPY: 0
            },
            tRecover: {
                Imss: "",
                Email: ""
            },
            nameRules: [
                v => !!v || 'Escribe tu No Empleado o Alias',
                v => {
                    if (v !== undefined && v.length < 1) {
                        return 'No Empleado invalido'
                    }
                    return !!v
                }
            ],
            passRules: [
                v => !!v || 'Escribe tu contraseña',
            ],
            emailRules: [
                v => !!v || 'Escribe tu correo',
                v => /.+@.+/.test(v) || 'Correo invalido'
            ],
            imssRules: [
                v => !!v || 'No Imss obligatorio.',
                v => {
                    const pattern = /^\d+(\.\d{1,2})?$/
                    return pattern.test(v) || 'Debes escribir tu No de IMSS'
                }
            ],
        }
    },
    methods: {
        plogin() {
            document.title = 'Login'
            this.cssRecovery = 'puff-out-center'
            this.cssRegistroViatico = 'puff-out-center'
            this.cssMain = 'main_contenedor'
            setTimeout(() => {
                this.isLogin = true
                this.isRecovery = false
                this.cssLogin = 'puff-in-center'
            }, 800);
        },
        precovery() {
            //debugger;
            var self = this;
            this.cssLogin = 'puff-out-center'
            this.cssMain = 'main_contenedor_recovery'

            //debugger;
            setTimeout(() => {
                this.isLogin = false
                this.isRecovery = true
                this.cssRecovery = 'puff-in-center'
            }, 800);
        },
        recovery(recover) {
            // debugger
            var self = this;
            if (self.cambiaMail) {
                if (!self.$refs.form2.validate())
                    return;
            }
            else if (self.tLogin.user === undefined || self.tLogin.user === '') {
                self.displayAlert(false, "Escribe tu Numero de empleado");
                return;
            }
            self.valid2 = false;
            var js = {
                alias: self.tLogin.user,
                Imss: self.tRecover.Imss,
                Emailv: self.tRecover.Email,
                idPrograma: self.project,
                recuperaContraseña: recover,
                supplier: self.isSupplier
            };
            axios.post("http://localhost:28359/userapi/Recovery", js)
                .then((data) => {
                    if (data.data.Success) {
                        self.displayAlert(null, data.data.Message);
                    }
                    else {
                        self.displayAlert(false, data.data.Message);
                    }
                    self.valid2 = true;
                    self.isVisible = false;
                })
                .catch((error) => {
                    self.valid2 = true;
                    self.displayAlert(false, error.message);
                    self.isVisible = false;
                });
        },
        submit() {
            //debugger
            var self = this;
            self.$refs.form.validate()

            self.displayAlert(false, "Procesando usuario...");
            var entorno = process.env.NODE_ENV === 'development' ? true : false

            var alias = self.tLogin.user;
            var password = self.tLogin.pass;
            var dev = entorno;
            var idPrograma = self.project;
            var MiCosmic = false;

            var uri = "/api/Login/autorizacion?alias=" + alias + "&password=" + password + "&dev=" + dev + "&idPrograma=" + idPrograma + "&MiCosmic" + MiCosmic;
            self.axios.get(uri)
                .then((r) => {
                    if (r.data.success) {
                        self.displayAlert(true);
                        localStorage.setItem('token', JSON.stringify(r.data.data.token));
                        r.data.data.token = "";
                        // localStorage.setItem('user',JSON.stringify(r.data.data))  

                        self.$store.dispatch("setUser", r.data.data);
                        self.$router.push({ name: "Home" });
                    } else {

                        self.displayAlert(false, r.data.message);
                    }
                })
                .catch((e) => {

                    // self.$root.Mensaje(e.response.data, "error");
                    self.displayAlert(false, e.response.data);
                    console.log(e);
                });
        },
        displayAlert(exito, msg) {
            if (exito === null) {
                this.alert_color = 'secondary';
                this.success_alert = 'success';
                this.alert_msg = msg;
            }
            else if (exito) {
                this.alert_color = 'primary';
                this.success_alert = 'success';
                this.alert_msg = 'Acceso concedido';
            }
            else {
                this.alert_color = 'error';
                this.success_alert = 'error';
                this.alert_msg = ' ' + msg;
            }
            this.alert = true;
        },
    }
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.myRFC input {
    text-transform: uppercase;
}

.LoginAlign {
    padding-top: 10%;
}

@keyframes bounce-in-bck {
    0% {
        transform: scale(7);
        animation-timing-function: ease-in matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
        opacity: 0;
    }

    38% {
        transform: scale(1);
        animation-timing-function: ease-out matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
        opacity: 1;
    }

    55% {
        transform: scale(1.5);
        animation-timing-function: ease-in matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
    }

    72% {
        transform: scale(1);
        animation-timing-function: ease-out matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
    }

    81% {
        transform: scale(1.24);
        animation-timing-function: ease-in matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
    }

    89% {
        transform: scale(1);
        animation-timing-function: ease-out matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
    }

    95% {
        transform: scale(1.04);
        animation-timing-function: ease-in matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
    }

    100% {
        transform: scale(1);
        animation-timing-function: ease-out matrix(1.00, -0.25, 0.00, 1.00, 0, 0);
    }
}

.slide-out-elliptic-top-bck {
    -webkit-animation: slide-out-elliptic-top-bck 0.7s ease-in both;
    animation: slide-out-elliptic-top-bck 0.7s ease-in both;
}

@-webkit-keyframes slide-out-elliptic-top-bck {
    0% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 1;
    }
}

@keyframes slide-out-elliptic-top-bck {
    0% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 1;
    }
}

.bounce-in-top {
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
}

@-webkit-keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.puff-out-center {
    -webkit-animation: puff-out-center 1.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    animation: puff-out-center 1.1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
}

@-webkit-keyframes puff-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        opacity: 0;
    }
}

@keyframes puff-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        opacity: 0;
    }
}

.puff-in-center {
    -webkit-animation: puff-in-center 0.8s ease-in both;
    animation: puff-in-center 0.8s ease-in both;
}

@-webkit-keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}


.main_contenedor {
    background: #ffffff;
    /*background: #870000;
  background: -webkit-linear-gradient(to right, #870000, #190A05);
  background: linear-gradient(to right, #870000, #190A05);*/
    position: relative;
}

.main_contenedor_recovery {
    background: #ffffff;
    background: -webkit-linear-gradient(to right, #ffffff, #ffffff);
    background: linear-gradient(to right, #ffffff, #ffffff);
    position: relative;
}

.main_contenedor_registro {
    background: #373B44;
    background: -webkit-linear-gradient(to right, #4286f4, #373B44);
    background: linear-gradient(to right, #4286f4, #373B44);
    position: relative;
}

.main_contenedor_regviatico {
    background: #0f0c29;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


@media screen and (max-width: 600px) {
    .logo {
        width: 18%;
        /*right: 120px;
      top: 5px;
      height: auto;
      position: absolute;
      animation: bounce-in-bck 1.1s both;*/
    }
}
</style>
