import Vue from 'vue'
import VueRouter from 'vue-router'
import moment from "moment";
import Home from "../views/Home.vue";
import Externos from '../views/Externos.vue';
import Login from "../components/Login.vue";
import Error401 from '../views/Error401.vue';
import Corporativo from '../views/Corporativo.vue';
import Responsivas from '../views/ResponsivasEquipos.vue';
import Repse from '../views/Repse.vue';
import BusinessReview from '../views/BusinessReview.vue'
// import store from '../store/index.js';

Vue.use(VueRouter)
Vue.use(require('vue-moment'), {
  moment
})

const routes = [
  {
    path: '/',
    alias: ['/login'],
    name: 'Login',
    component: Login,
    meta: {
      title: "Login - Tipos De Contratos"
    },
  }, 
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home - Tipos De Contratos"
    },
  },
  {
    path: '/Externos',
    name: 'Externos',
    component: Externos,
    meta: {
      title: "Externos - Tipos De Contratos"
    },
  },
  {
    path: '/Error401',
    name: 'Error401',
    component: Error401,
    meta: {
      title: "Error"
    },
  }, 
  {
    path: '/Corporativo',
    name: 'Corporativo',
    component: Corporativo,
    meta: {
      title: "Corporativo - Tipos De Contratos"
    },
  },
  {
    path: '/ContratoEquipos',
    name: 'Contrato de Equipos',
    component: Responsivas,
    meta: {
      title: "/Contrato de Equipos - Tipos De Contratos"
    },
  },
  {
    path: '/Repse',
    name: 'Repse',
    component: Repse,
    meta: {
      title: "/Repse - Tipos De Contratos"
    },
  },
  {
    path: '/BusinessReview',
    name: 'BusinessReview',
    component: BusinessReview,
    meta: {
      title: "/BusinessReview - Tipos De Contratos"
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');
  document.title =  to.meta.title ? to.meta.title : "Tipos de Contratos"
  
  if(authRequired && !loggedIn)
  {
    //document.title =  to.meta.title ? to.meta.title : "Tipos de Contratos - "
    next('/login');
  }
  else{
    next();
  }
  
});
export default router
