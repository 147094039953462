import createPersistedState from "vuex-persistedstate";
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    sIsBusy: false,
    sIsLogin: false,
    sMessage:{ show: false, message: "", color: ""  },
    sUsuario: { usuario: { alias: "", correo: "" } }
  };
};

export default new Vuex.Store({

  state: getDefaultState,
  // state: {
  //   getDefaultState,
  //   content: '',
  //   color: ''
  // },
  getters: {
    isAuthenticated: (state) => !!state.sUsuario,
    sIsLogin: (state) => state.sIsLogin,
    sIsBusy: (state) => state.sIsBusy,
    sMessage: (state) => state.sMessage,
    sUsuario: (state) => state.sUsuario,
  },
  mutations: {
    setIsBusy(state, payload) {
      state.sIsBusy = payload
    },
    setIsLogin(state, payload) {
      state.sIsLogin = payload
    },
    setUser(state, payload) {
      state.sUsuario = payload
    },
    showMessage(state, payload) {
      state.sMessage = payload.content
    }
  },
  actions: {
    setIsBusy({ state, commit }, valor) {
      commit('setIsBusy', valor)
    },
    setIsLogin({ state, commit }, valor) {
      commit('setIsLogin', valor)
    },
    setUser({ state, commit }, user) {
      commit('setUser', user)
    },
    showMessage({ state, commit }, valor) {
      //debugger
      commit('showMessage', valor)
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
