import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.prototype.$axios = axios
Vue.use(VueAxios, axios)
Vue.prototype.$uriUsuariosApi = 'http://login.cosmic.mx/'

Vue.prototype.$Snack = {
  color: 'error',
  mode: 'vertical',
  snackbar: false,
  text: '',
  timeout: 2000,
  x: 'left',
  y: 'top'
}

// Vue.prototype.$uriUsuarios = 'http://localhost:28359/login?project=FC621CEC-9201-4902-9970-76F279D432E2&dev=True'
Vue.prototype.$uriUsuarios = 'https://api.juridico.cosmic.mx/'
// Vue.prototype.$uriApi = 'https://localhost:44344/api/'
Vue.prototype.$uriApi = 'https://api.juridico.cosmic.mx/'
// axios.defaults.baseURL ='https://localhost:44342/'
axios.defaults.baseURL =  'https://api.juridico.cosmic.mx/'
// axios.defaults.baseURL = 'https://localhost:44344/'
Vue.config.productionTip = true
// Vue.config.devtools = true;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  data: () => ({

  }),
  methods: {
    Mensaje(mensaje, tipo = 'success') {
      if (tipo == 'success')
        tipo = "#28B463";

      if (tipo == 'error')
        tipo = "#FF0000";

      var sMessage = { show: true, message: mensaje, color: tipo }

      this.$store.dispatch("showMessage", sMessage);
    },
    // isBusy (ocupado) {
    //   this.$esBusy = ocupado
    //   console.log('me.$root.isBusy() ' + ocupado)
    // },
    setLogin(user) {
      this.$isLogin = true
      this.$vUser = user
    }
  }
}).$mount('#app')
