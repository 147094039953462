<template>
  <div class="home">
    <img  width="550" height="400" alt="Vue logo" src="../assets/logocosmic.png">
    <Layout msg="" title=""/>
  </div>
</template>

<script>
// @ is an alias to /src
import Layout from '@/views/Layout.vue'

export default {
  name: 'Home',
  components: {
    Layout
  }
}
</script>
