<template>
  <v-data-table :headers="headers" :items="fillData" sort-by="Id" class="elevation-1" style="border: 1px solid #ccc;">
    <template v-slot:item.inicio="{ item }">
      <span>{{ formatoFecha(item.inicio) }}</span>
    </template>
    <template v-slot:item.termino="{ item }">
      <span>{{ formatoFecha(item.termino) }}</span>
    </template>
    <template v-slot:item.monto="{ item }">
      <span>{{ formatoDinero(item.monto) }}</span>
    </template>
    <template v-slot:item.deposito="{ item }">
      <span>{{ formatoDinero(item.deposito) }}</span>
    </template>
    <template v-slot:item.vigente="{ item }">
      <v-chip :color="getColor(item.vigente)" dark>
        <span v-if="item.vigente == 1"></span>
        <span v-else-if="item.vigente == 3"></span>
        <span v-else-if="item.vigente == 2"></span>
      </v-chip>
    </template>
    <template v-slot:top>
      <Layout msg="Welcome to Your Vue.js App" />
      <v-toolbar flat>
        <v-toolbar-title>Corporativo</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" transition="dialog-bottom-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on">
              Agregar
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <!-- <i class="fa-solid fa-circle-xmark d-flex justify-end mb-6 fa-xl" @click="close()" style="color: red"></i> -->
              <v-toolbar color="red" dark>{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-card class="mb-12" height="100%">
                    <v-form ref="form" v-model="formValid" lazy-validation>
                      <!-- Plaza -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-select v-model="savedItems.idCatPlaza" :rules="plazaRules" :items="idCatPlazaCorporativo"
                            :item-text="'nombre'" :item-value="'idCatPlazaCorporativo'" label="Plaza" filled color="red"
                            required></v-select>
                        </v-col>
                      </v-row>
                      <!-- Arrendador -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.arrendador" :rules="arrendadorRules" label="Arrendador" filled
                            color="red" required></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Arrendatario -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-select v-model="savedItems.idCatArrendatario" :rules="arrendatarioRules"
                            :items="idCatArrendatario" :item-text="'nombre'" :item-value="'idCatArrendatario'"
                            label="Arrendatario" filled color="red" required></v-select>
                        </v-col>
                      </v-row>
                      <!-- Ubicación -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.ubicacion" :rules="ubicacionRules" label="Ubicación" filled
                            color="red" required></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Periodo - Vigencia -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.periodo" :rules="periodoRules" label="Vigencia" filled
                            color="red" required></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Inicio - Termino -->
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-date-picker color="red" v-model="savedItems.dateRangeText" range required></v-date-picker>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="savedItems.dateRangeText" label="Date range" prepend-icon="mdi-calendar"
                            readonly color="red" required></v-text-field>
                          fecha: {{ savedItems.dateRangeText }}
                        </v-col>
                      </v-row>
                      <!-- Depósito -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.deposito" :rules="depositoRules" label="Depósito" filled
                            color="red" prefix="$" required></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Monto - Mensualidad -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.monto" :rules="montoRules" label="Mensualidad" filled
                            color="red" prefix="$" required></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Penalización -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.penalizacion" label="Penalización" filled
                            color="red"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Observación -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="savedItems.observacion" :rules="observacionRules" label="Observación"
                            filled color="red" required></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card>
                </v-container>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="success" @click="save()"> Guardar </v-btn>
                <v-btn text @click="dialog.value = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">¿Está seguro de eliminar el registro?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Aceptar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ContratosFile :id="varContratosFile.id" :tipoContrato="varContratosFile.tipo" :archivos="archivos"
          :visible="dialogfile" @close="dialogfile = false" />
        <Bitacora :id="varContratosFile.id" :tipoContrato="varContratosFile.tipo" :bitacora="bitacora"
          :visible="dialogbitacora" @close="dialogbitacora = false" />
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="sendSolicitud(item)" v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1"> mdi-send </v-icon>
              </v-btn>
            </template>
            <span>Enviar Solicitud</span>
          </v-tooltip> -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="editItem(item, false)" v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Desactivar</span>
            <!-- <span v-if="item.eliminado == true">Activar</span>
            <span v-else>Desactivar</span> -->
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="odialogfile(item)" v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1">
                  mdi-file-document-arrow-right-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Cargar contrato</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="editItem(item, true)" v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1"> mdi-content-copy </v-icon>
              </v-btn>
            </template>
            <span>Renovar contrato</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="odialogbitacora(item)" v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1">mdi-history</v-icon>
              </v-btn>
            </template>
            <span>Bitacora</span>
          </v-tooltip>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import Layout from "@/views/Layout.vue"
import ContratosFile from "@/components/ContratosFile.vue"
import Bitacora from "@/components/Bitacora.vue"

export default {
  name: "Constantes",
  components: {
    Layout,
    ContratosFile,
    Bitacora,
  },
  data: () => ({
    options: ['Editar', 'Eliminar', 'Guardar'],
    selectedOption: null,
    menuOpen: false,
    loading: false,
    varContratosFile: {
      id: 0,
      //Tipo 1 contratos externos
      //Tipo 2 contratos corporativos
      tipo: 2
    },
    esRenovacion: false,
    valid: true,
    dialog: false,
    dialogfile: false,
    dialogbitacora: false,
    dialogDelete: false,
    idCatPlazaCorporativo: [],
    idCatArrendatario: [],
    idCatEstatusContrato: [],
    fillData: [],
    archivos: [],
    bitacora: [],
    savedIndex: -1,
    headers: [
      // {
      //   text: "Contrato Corporativo",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Plaza ", value: "nombre_Plaza", align: 'center', sortable: false },
      { text: "Arrendador", value: "arrendador", align: 'center', sortable: false },
      { text: "Arrendatario", value: "nombre_Arrendatario", align: 'center', sortable: false },
      { text: "Ubicación", value: "ubicacion", align: 'center', sortable: false },
      { text: "Vigencia", value: "periodo", align: 'center', sortable: false },
      { text: "Inicio", value: "inicio", align: 'center', sortable: false },
      { text: "Termino", value: "termino", align: 'center', sortable: false },
      { text: "$ Depósito", value: "deposito", align: 'center', sortable: false },
      { text: "$ Mensualidad", value: "monto", align: 'center', sortable: false },
      { text: "Penalización", value: "penalizacion", align: 'center', sortable: false },
      { text: "Observación", value: "observacion", align: 'center', sortable: false },
      { text: "Resguardo", value: "idCatEstatusResguardo", align: 'center', sortable: false },
      { text: "Vigente", value: "vigente", align: 'center', sortable: false },
      { text: "Acciones", value: "actions", align: 'center', sortable: false },
    ],
    savedItems: {
      id: 0,
      idCatPlaza: 0,
      arrendador: "",
      idCatArrendatario: 0,
      ubicacion: "",
      vigencia: "",
      dateRangeText: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      inicio: "",
      termino: "",
      monto: 0,
      deposito: 0,
      penalizacion: "",
      observacion: "",
      fechaCreacion: new Date().toISOString().slice(0, 10),
      idCatEstatusContrato: 1,
      idCatEstatusResguardo: 1,
      idparent: null,
    },
    formValid: false,
    plazaRules: [(v) => !!v || "Selecciona un campo"],
    arrendadorRules: [
      (v) => !!v || "Arrendador es requerido",
      (v) =>
        (v && v.length <= 50) ||
        "El arrendador debe tener maximo 50 caracteres",
    ],
    arrendatarioRules: [(v) => !!v || "Selecciona un campo"],
    ubicacionRules: [
      (v) => !!v || "Ubicación es requerido",
      (v) =>
        (v && v.length <= 50) || "La ubicación debe tener maximo 50 caracteres",
    ],
    periodoRules: [
      (v) => !!v || "Periodo es requerido",
      (v) =>
        (v && v.length <= 50) || "El Periodo debe tener maximo 50 caracteres",
    ],
    montoRules: [(v) => !!v || "Mensualidad es requerido"],
    depositoRules: [(v) => !!v || "Depósito es requerido"],
    penalizacionRules: [
      (v) => !!v || "Penalizacion es requerido",
      (v) =>
        (v && v.length <= 50) ||
        "La penalizacion debe tener maximo 50 caracteres",
    ],
    observacionRules: [
      (v) => !!v || "Observación es requerido",
      (v) =>
        (v && v.length <= 100) ||
        "Las observación debe tener maximo 100 caracteres",
    ],
  }),
  created() {
    this.$store.dispatch("setIsBusy", true);
    this.initialize();
    this.getCatPlaza();
    this.getCatArrendatario();
  },
  methods: {
    initialize() {
      // debugger;
      let vue = this;
      let token = JSON.parse(localStorage.getItem("token"));
      vue.$axios
        .get("api/Corporativo/get", {
          headers: { Authorization: "Bearer " + token },
        })
        .then((r) => {
          if (r.data.success) {
            vue.fillData = r.data.data;
            vue.$root.Mensaje(r.data.message, "success");
          } else {
            vue.$root.Mensaje(r.data.message, "error");
          }
          vue.$store.dispatch("setIsBusy", false);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            vue.$router.push({ name: "Error401" });
          } else {
            vue.$root.Mensaje(e.message, "error");
            console.log(e);
          }
          vue.$store.dispatch("setIsBusy", false);
        });
    },
    formatoDinero(item) {
      const dinero = item.toFixed(2)
      return dinero
    },
    formatoFecha(item) {
      const fecha = new Date(item + 'T00:00:00-06:00')
      const dia = String(fecha.getDate()).padStart(2, '0')
      const mes = String(fecha.getMonth() + 1).padStart(2, '0')
      const anio = String(fecha.getFullYear()).slice(-2)
      return `${dia}/${mes}/${anio}`
    },
    selectOption(option) {
      this.selectedOption = option;
      console.log(`Se seleccionó la opción "${option}"`);
      this.menuOpen = false;
    },
    isRowDisabled(item) {
      return item.estatus === 2;
    },
    getColor(vigente) {
      // debugger;
      if (vigente == 1) return "green"
      else if (vigente == 2) return "red"
      else if (vigente == 3) return "#cbd111"
    },
    deleteItem(item) {
      let vue = this;
      vue.savedIndex = vue.fillData.indexOf(item);
      vue.savedItems = Object.assign({}, item);
      vue.dialogDelete = true;
    },
    deleteItemConfirm() {
      const vue = this;
      var row = vue.fillData[vue.savedIndex]
      var id = row.id
      let token = JSON.parse(localStorage.getItem('token'));
      var u = vue.$store.state.sUsuario;
      vue.axios
        .post("api/Corporativo/desactivarSolicitud?id=" + id, { headers: { "Authorization": 'Bearer ' + token } })
        .then((r) => {
          if (r.data.success) {
            vue.$store.dispatch("setIsBusy", false);
            vue.fillData.splice(vue.savedIndex, 1);
            vue.closeDelete();
            // var newItem = r.data.data;
            // console.log(newItem);
            // vue.fillData.splice(indice, 1);
            // vue.fillData.push(newItem);
            // vue.$root.Mensaje(r.dara.message, "success");
          } else {
            vue.closeDelete();
            vue.$root.Mensaje(r.data.message, "error");
          }
        })
        .catch((e) => {
          vue.$store.dispatch("setIsBusy", false);
          vue.$root.Mensaje(e.message, "error");
          console.log(e);
        });
    },
    sendSolicitud(item) {
      var vue = this;
      let token = JSON.parse(localStorage.getItem("token"));
      var objeto = row;
      var u = vue.$store.state.sUsuario;
      vue.axios
        .post("api/Corporativo/enviarAutorizar", objeto, {
          headers: { Authorization: "Bearer" + token },
        })
        .then((r) => {
          if (r.data.success) {
            vue.$store.dispatch("setIsBusy", false);
            var newItem = r.data.data;
            console.log(newItem);
            vue.fillData.splice(indice, 1);
            vue.fillData.push(newItem);
            vue.$root.Mensaje(r.data.message, "success");
          } else {
            me.$root.Mensaje(r.data.message, "error");
          }
        })
        .catch((e) => {
          vue.$store.dispatch("setIsBusy", false);
          vue.$root.Mensaje(e.message, "error");
          console.log(e);
        });
    },
    //Consumo de APIs para el llenado de los Selects
    getCatPlaza() {
      let vue = this;
      vue.axios
        .get("api/CatPlazaCorporativo/obtenerPlazasCorporativo")
        .then((response) => {
          if (response.data.success) {
            vue.idCatPlazaCorporativo = response.data.data;
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            vue.$router.push({ name: "Error401" });
          } else {
            vue.$root.Mensaje(e.message, "error");
            console.log(e);
          }
        });
    },
    getCatArrendatario() {
      let vue = this;
      vue.axios
        .get("api/CatArrendatario/Get")
        .then((response) => {
          if (response.data.success) {
            vue.idCatArrendatario = response.data.data;
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            vue.$router.push({ name: "Error401" });
          } else {
            vue.$root.Mensaje(e.mesagge, "error");
            console.log(e);
          }
        });
    },
    //Salvar formulario mediante Api
    save() {
      try {
        var vue = this;
        vue.$store.dispatch("setIsBusy", true);
        var valida = this.$refs.form.validate();
        if (valida) {
          if (vue.esRenovacion) {
            //vue.savedItems.id = 0;
            vue.saveData(vue.savedItems, 1, vue.savedIndex);
          } else if (vue.savedIndex > -1) {
            vue.saveData(vue.savedItems, 0, vue.savedIndex);
          } else {
            vue.saveData(vue.savedItems, 1, vue.savedIndex);
          }
          vue.close();
        }
      } catch (e) {
        vue.$root.Mensaje(e.message, "error");
        console.log(e);
      }
    },
    saveData(row, n, indice) {
      var vue = this;
      var obj = row;
      let token = JSON.parse(localStorage.getItem("token"));
      var u = vue.$store.state.sUsuario;
      vue.axios
        .post("api/Corporativo/save", obj, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((r) => {
          if (r.data.success) {
            vue.$store.dispatch("setIsBusy", false);
            var newItem = r.data.data;
            if (n == 1) {
              console.log(newItem);
              //vue.fillData.splice(indice, 1);
              vue.fillData.push(newItem);
            } else {
              Object.assign(vue.fillData[indice], newItem);
              vue.fillData.push(newItem);
            }
            vue.$root.Mensaje(r.data.message, "success");
            // Get de Corporativo
            vue.$axios
              .get("api/Corporativo/get", {
                headers: { Authorization: "Bearer " + token },
              })
              .then((r) => {
                if (r.data.success) {
                  vue.fillData = r.data.data;
                }
                vue.$store.dispatch("setIsBusy", false);
              }) //
          } else {
            vue.$root.Mensaje(r.data.message, "error");
          }
        })
        .catch((e) => {
          vue.$root.dispatch("setIsBusy", false);
          vue.$root.Mensaje(e.message, "error");
          console.log(e);
        });
    },
    //Editar formulario mediante Api
    editItem(item, esRenovacion) {
      this.esRenovacion = esRenovacion;
      this.savedIndex = this.fillData.indexOf(item);
      this.savedItems = Object.assign({}, item);
      this.savedItems.dateRangeText = [
        this.savedItems.inicio,
        this.savedItems.termino,
      ];
      this.dialog = true;
    },
    //Cargar documentos
    odialogfile(item) {
      // debugger
      this.$store.dispatch("setIsBusy", true);
      this.varContratosFile.id = item.id;
      this.getFiles();
      this.dialogfile = true;
    },
    odialogbitacora(item) {
      this.dialogbitacora = true;
      this.varContratosFile.id = item.id;
      this.getBitacora();
    },
    getFiles() {
      let vue = this;
      let token = JSON.parse(localStorage.getItem("token"));

      // vue.$store.dispatch("setIsBusy", true);
      vue.$axios
        .get(
          "api/Archivo/getFiles?tipo=" +
          vue.varContratosFile.tipo +
          "&id=" +
          vue.varContratosFile.id,
          { headers: { Authorization: "Bearer " + token } }
        )
        .then((r) => {
          if (r.data.success) {
            vue.archivos = r.data.data;
            vue.$root.Mensaje(r.data.message, "success");
          } else {
            vue.$root.Mensaje(r.data.message, "error");
          }
          vue.$store.dispatch("setIsBusy", false);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            vue.$router.push({ name: "Error401" });
          } else {
            vue.$root.Mensaje(e.message, "error");
            console.log(e);
          }
          vue.$store.dispatch("setIsBusy", false);
        });
    },
    getBitacora() {
      // debugger;
      let vue = this;
      let token = JSON.parse(localStorage.getItem("token"));
      // vue.$store.dispatch("setIsBusy", true);
      vue.axios
        .get("api/Corporativo/getBitacora?id=" + vue.varContratosFile.id, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((r) => {
          if (r.data.success) {
            vue.bitacora = r.data.data;
            // vue.$store.dispatch("setIsBusy", false);
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            vue.$router.push({ name: "Error401" });
          } else {
            // vue.$store.dispatch("setIsBusy", false);
            vue.$root.Mensaje(e.message, "error");
            console.log(e);
          }
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.savedItems = Object.assign({}, this.defaultItem);
        this.editIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.savedItems = Object.assign({}, this.defaultItem);
        this.savedIndex = -1;
      });
      this.savedItems = this.defaultItems
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    dateRangeText() {
      // debugger;
      return this.savedItems.dateRangeText.join(" ~ ");
    },
    formTitle() {
      return this.savedIndex === -1 ? "Nuevo" : "Editar";
    },
  },
};
</script>

<style>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 10px 8px !important;
  transition: height .2s cubic-bezier(.4, 0, .6, 1);
}
</style>


