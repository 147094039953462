<template>
  <v-dialog v-model="show" max-width="100%">
    <v-card>
      <v-card-title class="text-h5">Bitácora de contrato</v-card-title>
      <v-card-actions>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table :headers="header" :items="bitacora" class="elevation-1">
              <template v-slot:item.inicio="{ item }">
                <span>{{ formatoFecha(item.inicio) }}</span>
              </template>
              <template v-slot:item.termino="{item}">
                <span>{{ formatoFecha(item.termino) }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Contratos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="descarga(item)" v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1"> mdi-download </v-icon>
                    </v-btn>
                  </template>
                  <span>Descarga contrato</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-spacer></v-spacer>
            <v-btn color="red" class="ma-2 white--text" @click="show = false">
              Cerrar
            </v-btn>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formToJSON } from "axios";

export default {
  data: () => ({
    header: [
      // {
      //   text: "Id",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Plaza", value: "nombre_Plaza", align: 'center', sortable: false },
      { text: "Arrendador", value: "arrendador", align: 'center', sortable: false },
      { text: "Arrendatario", value: "nombre_Arrendatario", align: 'center', sortable: false },
      { text: "Ubicación", value: "ubicacion", align: 'center', sortable: false },
      { text: "Periodo", value: "periodo", align: 'center', sortable: false },
      { text: "Inicio", value: "inicio", align: 'center', sortable: false },
      { text: "Termino", value: "termino", align: 'center', sortable: false },
      { text: "$ Depósito", value: "deposito", align: 'center', sortable: false },
      { text: "$ Monto", value: "monto", align: 'center', sortable: false },
      { text: "Penalización", value: "penalizacion", align: 'center', sortable: false },
      { text: "Observación", value: "observacion", align: 'center', sortable: false },
      { text: "Coordinador", value: "coordinador", align: 'center', sortable: false },
      // { text: "Vigente", value: "Vigente" },
      // { text: "Acción", value: "actions", sortable: false },
    ],
    file: "",
    editedIndex: -1,
    dialogDelete: false,
    editedItem: [],
    ids: 0,
  }),
  created() { },
  props: {
    visible: Boolean,
    id: Number,
    tipoContrato: Number,
    bitacora: Array,
  },
  methods: {
    formatoFecha(item){
      const fecha = new Date(item + 'T00:00:00-06:00')
      const dia = String(fecha.getDate()).padStart(2, '0')
      const mes = String(fecha.getMonth() + 1).padStart(2, '0')
      const anio = String(fecha.getFullYear()).slice(-2)
      return `${dia}/${mes}/${anio}`
    },
    close() {
      this.dialogarchivo = false;
    },
    descarga(item) {
      debugger;
      let me = this;
      let token = JSON.parse(localStorage.getItem("token"));
      me.$axios
        .get(
          "api/Archivo/downloadZip?tipo=" + me.tipoContrato + "&id=" + item.id,
          {
            headers: { Authorization: "Bearer " + token },
            responseType: "blob",
          }
        )
        .then((r) => {
          var blob = new Blob([r.data], { type: "application/zip" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = `${"Contrato_" + item.inicio + "_" + item.termino}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((e) => {
          if (e.response.status == 401) {
            me.$router.push({ name: "Error401" });
          } else {
            this.$root.Mensaje(e.message, "error");
            console.log(e);
          }
        });
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  mounted() {
    if (this.tipoContrato == 2) {
      this.header.splice(12, 1);
    }
  },
};
</script>
