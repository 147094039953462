<template>
  <div class="NotFound"><v-container><v-layout row wrap><v-flex xs12>
                  <v-card height="400" color="transparent" flat>
                      <div class="display-3 mt-5">No autorizado

                      </div>
                      <div class="grey--text lighten-5">
                          No tiene permisos para acceder a esta página.
                      </div>
                      <div class="paragraph-text mt-2"><br /><br />Su sesión 
                        ha caducado o no tiene autorización para acceder a este sitio.
                      </div>
                     
                      <v-btn v-on:click="logout" depressed color="error">
                          Cerrar sesión
                      </v-btn>
                  </v-card></v-flex></v-layout></v-container></div>
</template>

<script>
export default ({
  data: () => ({

  }),
  created() {


  },
  props: {
      title: String,
      isbusy: Boolean
  },
  methods: {
      initialize() {
      },

      logout() {
          window.localStorage.clear();
          this.$router.push({ name: 'Login' });
      }
  }
}
)

</script>
<style>
.paragraph-text {
  font-size: 18px;
}
</style>