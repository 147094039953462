<template>
    <v-dialog v-model="show" max-width="700px">
        <v-card>
            <v-card-title class="text-h5">Carga de Contratos</v-card-title>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-data-table :headers="header" :items="archivos" class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title>Contratos</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <v-card>
                                            <v-card-title class="text-h5">¿Seguro que desea eliminar el archivo?
                                            </v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                                <v-btn color="blue darken-1" text @click="eliminarConfirm">Aceptar</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="descarga(item)" v-bind="attrs" v-on="on">
                                            <v-icon color="grey lighten-1"> mdi-arrow-down-bold-circle-outline </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Descargar</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="eliminar(item)" v-bind="attrs" v-on="on">
                                            <v-icon color="grey lighten-1"> mdi-delete-variant </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <div>
                            <label>
                                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
                            </label>
                            <br />
                            <!-- <button v-on:click="submitFile()">Submit</button> -->
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn color="red" class="ma-2 white--text" @click="show = false">
                            Cerrar
                        </v-btn>
                        <v-btn color="blue-grey" class="ma-2 white--text" @click="submitFile()">
                            Cargar
                            <v-icon right dark>
                                mdi-cloud-upload
                            </v-icon>
                        </v-btn>
                        <!-- <v-btn v-on:click="submitFile()" color="blue-grey" text>Cargar</v-btn> -->
                        <v-spacer></v-spacer>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default ({
    data: () => ({
        file: '',
        editedIndex: -1,
        dialogDelete: false,
        editedItem: [],
        ids: 0,
        header: [
            // { text: 'Id', value: 'id' },
            { text: "Nombre", value: "nombre", align: 'center', sortable: false },
            { text: "Acción", value: "actions", align: 'center', sortable: false  },
        ],
    }),
    created() {

    },
    props: {
        visible: Boolean,
        id: Number,
        tipoContrato: Number,
        archivos: Array
    },
    methods: {
        submitFile() {
            //debugger
            let me = this;
            me.$store.dispatch("setIsBusy", true);
            let token = JSON.parse(localStorage.getItem('token'));
            me.ids = me.id;
            if (me.file == '') {
                me.$root.Mensaje("Selecciona un archivo primero", "error");
            }
            else {
                debugger
                let formData = new FormData();
                formData.append('file', me.file);
                formData.append('tipoContrato', me.tipoContrato);
                formData.append('id', me.id);
                me.axios.post('api/Archivo/UploadFile',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization": 'Bearer ' + token
                        }
                    }
                ).then((r) => {
                    if (r.data.success) {
                        me.$root.Mensaje(r.data.message, "success");
                        me.archivos.push(r.data.data)
                    } else {
                        me.$root.Mensaje(r.data.message, "error");
                    }
                    me.$store.dispatch("setIsBusy", false);
                })
                    .catch((e) => {
                        if (e.response.status == 401) {
                            me.$router.push({ name: "Error401" });
                        }
                        else {
                            me.$root.Mensaje(e.message, "error");
                            console.log(e);
                        }
                        me.$store.dispatch("setIsBusy", false);
                    });
            }
        },
        // descarga(item) {
        //     //debugger;
        //     let me = this;
        //     let token = JSON.parse(localStorage.getItem('token'));
        //     me.$store.dispatch("setIsBusy", true);
        //     me.$axios
        //         .get("api/Archivo/downLoad?idArchivoExterno=" + item.idArchivoExterno, { headers: { "Authorization": 'Bearer ' + token }, responseType: 'blob' })
        //         .then((r) => {
        //             var blob = new Blob([r.data]);
        //             const a = document.createElement('a');
        //             a.href = window.URL.createObjectURL(blob);
        //             a.download = `${item.nombre}`;
        //             document.body.appendChild(a);
        //             a.click();
        //             a.remove();
        //             me.$store.dispatch("setIsBusy", false);
        //             me.$root.Mensaje(r.data.message, "success");
        //         })
        //         .catch((e) => {
        //             if (e.response.status == 401) {
        //                 me.$router.push({ name: "Error401" });
        //             }
        //             else {
        //                 me.$root.Mensaje(e.message, "error");
        //                 console.log(e);
        //             }
        //             me.$store.dispatch("setIsBusy", false);
        //         });
        // },
        descarga(item) {
            //debugger;
            let me = this;
            let idArchivo = 0
            let tipoContrato = 0
            let token = JSON.parse(localStorage.getItem('token'));
            me.$store.dispatch("setIsBusy", true);
            if(item.idArchivoExterno != null)
            {
                idArchivo = item.idArchivoExterno
                tipoContrato = 1
            } 
            if(item.idArchivoCorporativo != null)
            {
                idArchivo = item.idArchivoCorporativo
                tipoContrato = 2
            }
            me.$axios
                .get("api/Archivo/descargarArchivo?idArchivo=" + idArchivo + "&tipoContrato=" + tipoContrato, { headers: { "Authorization": 'Bearer ' + token }, responseType: 'blob' })
                .then((r) => {
                    var blob = new Blob([r.data]);
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = `${item.nombre}`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    me.$store.dispatch("setIsBusy", false);
                    // me.$root.Mensaje(r.data.message, "success");
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        me.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                    me.$store.dispatch("setIsBusy", false);
                });
        },
        eliminar(item) {
            this.editedIndex = this.archivos.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        eliminarConfirm() {
            //debugger;
            var item = this.editedItem;
            let me = this;
            let idArchivo = 0
            let tipoContrato = 0
            me.$store.dispatch("setIsBusy", true);
            let token = JSON.parse(localStorage.getItem('token'));
            if(item.idArchivoExterno != null)
            {
                idArchivo = item.idArchivoExterno
                tipoContrato = 1
            } 
            if(item.idArchivoCorporativo != null)
            {
                idArchivo = item.idArchivoCorporativo
                tipoContrato = 2
            }
            me.$axios
                .get("api/Archivo/eliminarArchivo?idArchivo=" + idArchivo + "&tipoContrato=" + tipoContrato, { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        me.archivos.splice(this.editedIndex, 1)
                        me.dialogDelete = false
                        me.$store.dispatch("setIsBusy", false);
                        me.$root.Mensaje(r.data.message, "success");

                    } else {
                        me.$store.dispatch("setIsBusy", false);
                        me.$root.Mensaje(r.data.message, "error");
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        me.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                });
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
    }
}
)
</script>