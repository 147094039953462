<template>
    <v-data-table :headers="headers" :items="listObject" sort-by="Id" class="elevation-1" style="border: 1px solid #ccc;">
        <template v-slot:item.inicio="{ item }">
            <span>{{ formatoFecha(item.inicio) }}</span>
        </template>
        <template v-slot:item.termino="{ item }">
            <span>{{ formatoFecha(item.termino) }}</span>
        </template>
        <template v-slot:item.deposito="{ item }">
            <span>{{ formatoDinero(item.deposito) }}</span>
        </template>
        <template v-slot:item.monto="{ item }">
            <span>{{ formatoDinero(item.monto) }}</span>
        </template>
        <template v-slot:item.vigente="{ item }">
            <v-chip :color="getColor(item.vigente)" dark>
                <span v-if="item.vigente == 1"></span>
                <span v-else-if="item.vigente == 3"></span>
                <span v-else-if="item.vigente == 2"></span>
            </v-chip>
        </template>
        <template v-slot:top>
            <Layout msg="Welcome to Your Vue.js App" />
            <v-toolbar flat>
                <v-toolbar-title>Externos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="600px" transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn color="primary" @click="showSnackbar">
                            Show snackbar
                        </v-btn> -->
                        <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on">
                            Agregar
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-card>
                            <!-- <i class="fa-solid fa-circle-xmark d-flex justify-end mb-6 fa-xl" @click="close()" style="color: red"></i> -->
                            <!-- <v-card-title class="text-h5">{{ formTitle }}</v-card-title> -->
                            <v-toolbar color="red" dark>{{ formTitle }}</v-toolbar>
                            <v-card-text>
                                <v-container>
                                    <v-card class="mb-12" height="100%">
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.idCatPlaza" :items="idCatPlazaExterno"
                                                        :item-text="'nombre'" :item-value="'idCatPlazaExterno'"
                                                        :rules="[(v) => !!v || 'Selecciona un campo']" label="Plaza" filled
                                                        color="red" required></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.arrendador" label="Arrendador" filled
                                                        color="red" :rules="ruleArrendador"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.idCatArrendatario"
                                                        :items="listarrendatario" :item-text="'nombre'"
                                                        :item-value="'idCatArrendatario'"
                                                        :rules="[(v) => !!v || 'Selecciona un campo']"
                                                        label="Nombre del arrendatario" filled color="red"
                                                        required></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.ubicacion" label="Ubicacion" filled
                                                        color="red" :rules="ruleUbicacion"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.periodo" label="Vigencia" filled
                                                        color="red" :rules="rulePeriodo"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-date-picker v-model="editedItem.dateRangeText" range filled required
                                                        color="red"></v-date-picker>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field v-model="editedItem.dateRangeText" label="Date range"
                                                        filled color="red" prepend-icon="mdi-calendar" readonly
                                                        required></v-text-field>
                                                    fecha: {{ editedItem.dateRangeText }}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.deposito" label="Deposito" prefix="$"
                                                        filled color="red" :rules="ruleDeposito"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.monto" label="Mensualidad" prefix="$"
                                                        filled color="red" :rules="ruleMonto"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.penalizacion" label="Penalizacion"
                                                        filled color="red" :rules="rulePenalizacion"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.observacion" label="Observacion"
                                                        filled color="red" :rules="ruleObservacion"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.coordinador" label="Coordinador"
                                                        filled color="red" :rules="ruleCoordinador"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn color="success" class="ma-2 white--text" @click="save"> Guardar </v-btn>
                                <!-- <v-btn color="red" text @click="dialog.value = false">Cerrar</v-btn> -->
                                <v-btn color="red" class="ma-2 white--text" @click="dialog.value = false">
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">¿Está seguro de eliminar el registro?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Aceptar</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <ContratosFile :id="varContratosFile.id" :tipoContrato="varContratosFile.tipo" :archivos="archivos"
                    :visible="dialogfile" @close="dialogfile = false" />

                <Bitacora :id="varContratosFile.id" :tipoContrato="varContratosFile.tipo" :bitacora="bitacora"
                    :visible="dialogbitacora" @close="dialogbitacora = false" />
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <!-- <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="sendSolicitud(item)" v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-send </v-icon>
                            </v-btn>
                        </template>
                        <span>Enviar Solicitud</span>
                    </v-tooltip> -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="editItem(item, false)" v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-delete </v-icon>
                            </v-btn>
                        </template>
                        <span v-if="item.eliminado == true">Activar</span>
                        <span v-else>Desactivar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="odialogfile(item)" v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-file-document-arrow-right-outline </v-icon>
                            </v-btn>
                        </template>
                        <span>Cargar contrato</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="editItem(item, true)" v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-content-copy </v-icon>
                            </v-btn>
                        </template>
                        <span>Renovar contrato</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="odialogbitacora(item)" v-bind="attrs" v-on="on">
                                <v-icon color="grey lighten-1"> mdi-history </v-icon>
                            </v-btn>
                        </template>
                        <span>Bitacora</span>
                    </v-tooltip>
                </v-list>
            </v-menu>

        </template>
    </v-data-table>
</template>

<script>
import Layout from '@/views/Layout.vue'
import ContratosFile from '@/components/ContratosFile.vue'
import Bitacora from '@/components/Bitacora.vue'

export default {
    name: 'Constantes',
    components: {
        Layout,
        ContratosFile,
        Bitacora
    },
    data: () => ({
        options: ['Editar', 'Eliminar', 'Guardar'],
        selectedOption: null,
        menuOpen: false,
        loading: false,
        varContratosFile: {
            id: 0,
            //Tipo 1 contratos externos
            //Tipo 2 contratos corporativos
            tipo: 1
        },
        esRenovacion: false,
        valid: true,
        dialog: false,
        dialogfile: false,
        dialogbitacora: false,
        listObject: [],
        idCatPlazaExterno: [],
        archivos: [],
        bitacora: [],
        listarrendatario: [],
        listcontrato: [],
        listestadocontrato: [],
        editedIndex: -1,
        dialogDelete: false,
        editedItem: {
            idExterno: 0,
            idCatPlaza: 0,
            dateRangeText: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            arrendador: "",
            idCatArrendatario: 0,
            ubicacion: "",
            periodo: "",
            inicio: "",
            termino: "",
            monto: 0,
            deposito: 0,
            penalizacion: "",
            observacion: "",
            coordinador: "",
            idCatEstatusContrato: 1,
            idCatEstatusResguardo: 1,
            idparent: null
        },
        headers: [
            // {
            //     text: "Id",
            //     align: "start",
            //     sortable: false,
            //     value: "id",
            // },
            { text: "Plaza", value: "nombre_Plaza", align: 'center', sortable: false },
            { text: "Arrendador", value: "arrendador", align: 'center', sortable: false },
            { text: "Arrendatario", value: "nombre_Arrendatario", align: 'center', sortable: false },
            { text: "Ubicacion", value: "ubicacion", align: 'center', sortable: false },
            { text: "Vigencia", value: "periodo", align: 'center', sortable: false },
            { text: "Inicio", value: "inicio", align: 'center', sortable: false },
            { text: "Termino", value: "termino", align: 'center', sortable: false },
            { text: "$ Deposito", value: "deposito", align: 'center', sortable: false },
            { text: "$ Mensualidad", value: "monto", align: 'center', sortable: false },
            { text: "Penalizacion", value: "penalizacion", align: 'center', sortable: false },
            { text: "Observacion", value: "observacion", align: 'center', sortable: false },
            { text: "Coordinador", value: "coordinador", align: 'center', sortable: false },
            { text: "Resguardo", value: "idCatEstatusResguardo", align: 'center', sortable: false },
            { text: "Vigente", value: "vigente", align: 'center', sortable: false },
            { text: "Acción", value: "actions", align: 'center', sortable: false },
        ],
        ruleArrendador: [
            (v) => !!v || "El nombre del arrendador es obligatorio",
            (v) =>
                (v && v.length <= 50) || "El arrendador debe tener maximo 50 caracteres",
        ],
        ruleUbicacion: [
            (v) => !!v || "El nombre de la ubicacion es obligatoria",
            (v) =>
                (v && v.length <= 50) || "El ubicacion debe tener maximo 100 caracteres",
        ],
        rulePeriodo: [
            (v) => !!v || "El nombre del periodo es obligatorio",
            (v) =>
                (v && v.length <= 50) || "El periodo debe tener maximo 50 caracteres",
        ],
        ruleMonto: [
            (v) => !!v || "El monto es obligatoria",
        ],
        ruleDeposito: [
            (v) => !!v || "El deposito es obligatoria",
        ],
        rulePenalizacion: [
            (v) => !!v || "La penalizacion es obligatoria",
            (v) =>
                (v && v.length <= 50) || "La penalizacion debe tener maximo 50 caracteres",
        ],
        ruleObservacion: [
            (v) => !!v || "La observacion es obligatoria",
            (v) =>
                (v && v.length <= 50) || "La observacion debe tener maximo 100 caracteres",
        ],
        ruleCoordinador: [
            (v) => !!v || "El nombre del coordinador es obligatoria",
            (v) =>
                (v && v.length <= 50) || "El coordinador debe tener maximo 50 caracteres",
        ],
    }),
    computed: {
        dateRangeText() {
            //debugger
            return this.editedItem.dateRangeText.join(' ~ ')
        },
        formTitle() {
            return this.editedIndex === -1 ? "Nuevo" : "Editar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
    },
    created() {
        this.$store.dispatch("setIsBusy", true);
        this.initialize();
        this.catplaza();
        this.catarrendatario();
    },
    methods: {
        initialize() {
            //debugger;
            let me = this;
            let token = JSON.parse(localStorage.getItem('token'));
            me.$axios
                .get("api/Externo/get", { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        //debugger
                        me.listObject = r.data.data;
                        me.$root.Mensaje(r.data.message, "success");
                    }
                    else {
                        me.$root.Mensaje(r.data.message, "error");
                    }
                    me.$store.dispatch("setIsBusy", false);
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        me.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                    me.$store.dispatch("setIsBusy", false);
                }
                );

            // me.$root.isBusy = false;
        },
        formatoDinero(item) {
            const dinero = item.toFixed(2)
            return dinero
        },
        formatoFecha(item) {
            const fecha = new Date(item + 'T00:00:00-06:00')
            const dia = String(fecha.getDate()).padStart(2, '0')
            const mes = String(fecha.getMonth() + 1).padStart(2, '0')
            const anio = String(fecha.getFullYear()).slice(-2)
            return `${dia}/${mes}/${anio}`
        },
        selectOption(option) {
            this.selectedOption = option;
            console.log(`Se seleccionó la opción "${option}"`);
            this.menuOpen = false;
        },
        isRowDisabled(item) {
            return item.estatus === 2;
        },
        getColor(vigente) {
            //debugger
            if (vigente == 1) return 'green'
            else if (vigente == 2) return 'red'
            else if (vigente == 3) return '#bdbf2e'
        },
        deleteItem(item) {
            let vue = this;
            vue.editedIndex = vue.listObject.indexOf(item);
            vue.editedItem = Object.assign({}, item);
            vue.dialogDelete = true;
        },
        deleteItemConfirm() {
            const vue = this;
            var row = vue.listObject[vue.editedIndex]
            var idExterno = row.id
            let token = JSON.parse(localStorage.getItem('token'));
            var u = vue.$store.state.sUsuario;
            vue.axios
                .post("api/Externo/desactivarSolicitud?id=" + idExterno, { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        vue.$store.dispatch("setIsBusy", false);
                        vue.listObject.splice(vue.editedIndex, 1);
                        vue.closeDelete();
                        // var newItem = r.data.data;
                        // console.log(newItem);
                        // vue.listObject.splice(indice, 1);
                        // vue.listObject.push(newItem);
                        // vue.$root.Mensaje(r.dara.message, "success");
                    } else {
                        vue.closeDelete();
                        vue.$root.Mensaje(r.data.message, "error");
                    }
                })
                .catch((e) => {
                    vue.$store.dispatch("setIsBusy", false);
                    vue.$root.Mensaje(e.message, "error");
                    console.log(e);
                });
        },
        sendSolicitud(item) {
            //debugger;
            var me = this;
            let token = JSON.parse(localStorage.getItem('token'));
            var objeto = row;
            var u = me.$store.state.sUsuario;
            me.axios
                .post("api/Externo/enviaAutorizar", objeto, { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        debugger;
                        me.$store.dispatch("setIsBusy", false);
                        var newItem = r.data.data;

                        console.log(newItem);
                        me.listObject.splice(indice, 1)
                        me.listObject.push(newItem);
                        // }
                        // else {
                        //     Object.assign(me.listObject[indice], newItem);
                        // }

                        me.$root.Mensaje(r.data.message, "success");
                    }
                    else {
                        me.$root.Mensaje(r.data.message, "error");
                    }

                })
                .catch((e) => {
                    me.$store.dispatch("setIsBusy", false);
                    me.$root.Mensaje(e.message, "error");
                    console.log(e);
                });
        },
        catplaza() {
            //debugger;
            let me = this;
            me.$axios
                .get("api/CatPlazaExternos/obtenerPlazasExterno")
                .then((r) => {
                    if (r.data.success) {
                        me.idCatPlazaExterno = r.data.data;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        this.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                });
        },
        catarrendatario() {
            //debugger;
            let me = this;
            me.$axios
                .get("api/CatArrendatario/get")
                .then((r) => {
                    if (r.data.success) {
                        me.listarrendatario = r.data.data;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        this.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                });
        },
        catcontrato() {
            //debugger;
            let me = this;
            me.$axios
                .get("api/CatContrato/get")
                .then((r) => {
                    if (r.data.success) {
                        me.listcontrato = r.data.data;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        this.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                });
        },
        catestado() {
            //debugger;
            let me = this;
            var idGrupoCategoria = this.$route.params.idGrupoCategoria;
            me.$axios
                .get("api/CatEstadoContrato/get")
                .then((r) => {
                    if (r.data.success) {
                        me.listestadocontrato = r.data.data;
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        this.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                });
        },
        save() {
            try {
                var me = this;
                me.$store.dispatch("setIsBusy", true);
                var valida = this.$refs.form.validate();
                if (valida) {
                    if (me.esRenovacion) {
                        //me.editedItem.id = 0
                        me.saveData(me.editedItem, 1, me.editedIndex);
                    }
                    else if (me.editedIndex > -1) {
                        me.saveData(me.editedItem, 0, me.editedIndex);
                    } else {
                        me.saveData(me.editedItem, 1, me.editedIndex);
                    }
                    me.close();
                }
            } catch (e) {
                me.$root.Mensaje(e.message, "error");
                console.log(e);
            }
        },
        saveData(row, n, indice) {
            //debugger;
            var me = this;
            let token = JSON.parse(localStorage.getItem('token'));
            var objeto = row;
            var u = me.$store.state.sUsuario;
            me.axios
                .post("api/Externo/save", objeto, { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        //debugger;
                        me.$store.dispatch("setIsBusy", false);
                        var newItem = r.data.data;
                        if (n == 1) {
                            console.log(newItem);
                            //me.listObject.splice(indice, 1)
                            me.listObject.push(newItem);
                        }
                        else {
                            Object.assign(me.listObject[indice], newItem);
                            me.listObject.push(newItem);
                        }

                        me.$root.Mensaje(r.data.message, "success");
                        // Get de Externos
                        me.$axios
                            .get("api/Externo/get", { headers: { "Authorization": 'Bearer ' + token } })
                            .then((r) => {
                                if (r.data.success) {
                                    me.listObject = r.data.data;
                                }
                                me.$store.dispatch("setIsBusy", false);
                            }) //
                    }
                    else {
                        me.$root.Mensaje(r.data.message, "error");
                    }

                })
                .catch((e) => {
                    me.$store.dispatch("setIsBusy", false);
                    me.$root.Mensaje(e.message, "error");
                    console.log(e);
                });
        },
        editItem(item, esRenovacion) {
            //debugger
            this.esRenovacion = esRenovacion;
            this.editedIndex = this.listObject.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.dateRangeText = [this.editedItem.inicio, this.editedItem.termino];
            this.dialog = true;
        },
        odialogfile(item) {
            //debugger
            this.$store.dispatch("setIsBusy", true);
            this.varContratosFile.id = item.id;
            this.getFiles();
            this.dialogfile = true;
        },
        odialogbitacora(item) {
            this.dialogbitacora = true;
            this.varContratosFile.id = item.id;
            this.getbitacora();
        },
        getFiles() {
            //debugger;
            let me = this;
            let token = JSON.parse(localStorage.getItem('token'));

            // me.$store.dispatch("setIsBusy", true);
            me.$axios
                .get("api/Archivo/getFiles?tipo=" + me.varContratosFile.tipo + "&id=" + me.varContratosFile.id, { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        me.archivos = r.data.data;
                        me.$root.Mensaje(r.data.message, "success");
                    } else {
                        me.$root.Mensaje(r.data.message, "error");
                    }
                    me.$store.dispatch("setIsBusy", false);
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        me.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                    me.$store.dispatch("setIsBusy", false);
                });

        },
        getbitacora() {
            //debugger;
            let me = this;
            let token = JSON.parse(localStorage.getItem('token'));
            // me.$store.dispatch("setIsBusy", true);
            me.$axios
                .get("api/Externo/getBitacora?id=" + me.varContratosFile.id, { headers: { "Authorization": 'Bearer ' + token } })
                .then((r) => {
                    if (r.data.success) {
                        me.bitacora = r.data.data;
                        // me.$store.dispatch("setIsBusy", false);
                    }
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        me.$router.push({ name: "Error401" });
                    }
                    else {
                        // me.$store.dispatch("setIsBusy", false);
                        this.$root.Mensaje(e.message, "error");
                        console.log(e);
                    }
                });
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.editedItem = this.defaultItems
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    }
}
</script>

