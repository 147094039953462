<template>
  <v-container>
    <v-navigation-drawer v-model="drawer" color="rgb(213 0 0 / 90%)" app>
      <v-list dense>
        <v-list-item-group color="white">
          <v-list-item-avatar> </v-list-item-avatar>
          <v-divider></v-divider>
          <v-list-item style="color: #fff" v-for="(item, i) in vusuario.menuDtoL" :key="i" router :to="item.urlMenu"
            v-bind:disabled="item.urlMenu == '/'">
            <v-list-item-icon>
              <v-icon style="color: #fff" v-text="item.icono"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.link"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#D50000" prominent dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Contratos - Convenios</v-toolbar-title>

      <v-spacer></v-spacer>

      <!--<ThemeChanger />-->

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on:click="logout" slot="activator" v-on="on">
            <v-icon large>mdi-location-exit</v-icon>
          </v-btn>
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>
    </v-app-bar>

    <v-dialog v-model="proc" hide-overlay persistent width="300">
      <v-card color="#B51E29" dark>
        <v-card-text>
          Procesando información...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar  v-model="snackbar.show" :timeout=3400 :color="snackbar.color" top right rounded="pill">
       <th style="font-weight: bold; font-size: 15px;">{{ snackbar.message }}</th>
    </v-snackbar>

  </v-container>
</template>

<script>

export default ({
  data: () => ({
    snackbar: {
      show: false,
      message: '',
      color: ''
    },
    drawer: false,

  }),

  created() {
    //document.title = this.$props.title;
    
    //document.title = "Tipos de contratos";

    this.initialize();

    this.$store.subscribe((mutation, state) => {
      //debugger
      if (mutation.type == "showMessage") {
        this.snackbar.message = mutation.payload.message
        this.snackbar.color = mutation.payload.color
        this.snackbar.show = true
      }
    })

  },
  computed: {
    vusuario() {
      //debugger
      var a = this.$store.getters.sUsuario
      return a
    },
    proc() {
      var a = this.$store.getters.sIsBusy
      return a
    },
  },

  props: {
    title: String
  },
  methods: {
    initialize() {
    },

    logout() {
      window.localStorage.clear();
      this.$router.push({ name: 'Login' });
    },
  }
}
)
</script>
<style scoped></style>
